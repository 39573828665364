import '@kuzorov/smoothscroll-polyfill';
import Loadeer from "loadeer";
import BackToTop from "./components/backtotop";
//import { createIcons, Instagram, Facebook } from 'lucide';
import Swiper from 'swiper';
import { Scrollbar } from 'swiper/modules';
import { initcookies } from './components/cookies';

/* createIcons({
    icons: {
        Instagram,
        Facebook
    }
}); */

const instance = new Loadeer();
instance.observe();

const $body = document.body;


function init() {
    console.log("init");

    let options = {
        modules: [Scrollbar],
        slidesPerView: 2,
        spaceBetween: 30,
        freeMode: true,
        scrollbar: {
            el: ".swiper-scrollbar",
        },
        breakpoints: {
            720: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 4,
            },
            1600: {
                slidesPerView: 5,
            },
        },
    };
    var swiper1 = new Swiper(".events-swiper", options);
    var swiper2 = new Swiper(".galleries-swiper", options);
    let spinners = document.querySelectorAll('.v-navigation');

    spinners.forEach(element => {
        element.addEventListener('mouseover', function () {
            let innerCircle = this.querySelector('.inner_circle');
            innerCircle.classList.remove('ani-paused');
        });

        element.addEventListener('mouseout', function () {
            let innerCircle = this.querySelector('.inner_circle');
            innerCircle.classList.add('ani-paused');
        });
    });


    initNavigation();

    // cookies
    initcookies(document.getElementById('btn_cookies'));

    //btt    
    let btt = new BackToTop(document.getElementById('btt'), 1000);

    // disable context menu for images
    let allImages = document.querySelectorAll("img");
    allImages.forEach((value) => {
        value.oncontextmenu = (e) => {
            e.preventDefault();
        }
    });
}


function initNavigation() {
    let items = document.querySelectorAll('.v-navigation');
    items.forEach(element => {
        element.addEventListener('click', handleMenuItem);
    });
}

let to;
function handleMenuItem(e) {
    let content_area = e.target.parentNode;
    let active_area = document.querySelector('.accordion:not(.inactive)');

    if (content_area.classList.contains('inactive')) {
        content_area.classList.remove('inactive');

        clearTimeout(to);
        to = setTimeout(() => {
            e.target.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        if (active_area)
            active_area.classList.add('inactive');
    }
    else {
        content_area.classList.add('inactive');
    }

    let any_active = document.querySelector('.accordion:not(.inactive)');
    let intro_area = document.querySelector('#intro_area');
    if (any_active)
        intro_area.classList.add('inactive');
    else {
        intro_area.classList.remove('inactive');
        clearTimeout(to);
        to = setTimeout(() => {
            document.body.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }
}

// Lightbox
Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
    element.onclick = (e) => {
        e.preventDefault();
        basicLightbox.create(`<img src="${element.href}">`).show();
    };
});



init();

