
export default class BackToTop {
    constructor(nativeElement, revertTime = 2500) {
        this.btn_btt = nativeElement;
        this.btt_revert_time = revertTime;

        if (this.btn_btt)
            this.init();
    }

    init() {
        this.btn_btt.addEventListener('click', this.topFunction);
        window.onscroll = function () { this.scrollFunction() };
        this.scrollStop(this.revert_btt, this.btt_revert_time);
    }

    scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            this.btn_btt.classList.add("showing");
        } else {
            this.btn_btt.classList.remove("showing");
        }
    }

    topFunction() {
        document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
    }

    revert_btt() {
        this.btn_btt.classList.remove("showing");
    }

    scrollStop(callback, refresh = 66) {
        // Make sure a valid callback was provided
        if (!callback || typeof callback !== 'function') return;

        let isScrolling;
        window.addEventListener('scroll', function (event) {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(callback, refresh);
        }, false);
    }
}




